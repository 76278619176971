<template>
  <div class="transactionDetail" v-if="showInfo" width="570px">

    <div class="certDetails" v-loading="isLoad">
      <span class="close el-icon-close" @click="handleClose"></span>
      <div class="certArea">
        <div class="certTop"></div>
        <div v-if="page== 1" class="certContent">
          <div class="lineItem">
            <p> <em>证</em><em>书</em><em>序</em><em>列</em><em>号</em> ：</p>
            <span>{{ certInfo.signCertSn }}</span>
          </div>
          <div class="lineItem" v-if="certInfo.certType !== 1">
            <p> <em>机</em><em>构</em><em>名</em><em>称</em><em>/O</em> ：</p>
            <span>{{ certInfo.organization }}</span>
          </div>
          <div class="unilateral lineItem" v-if="certInfo.certType !== 1">
            <p> <em>所</em><em>属</em><em>部</em><em>门</em><em>/OU</em> ：</p>
            <span>{{ certInfo.organizationalUnit }}</span>
          </div>
          <div class="wholeLine lineItem">
            <p> <em>名</em><em>称</em><em>/C</em> <em>N</em>：</p>
            <span>{{ certInfo.commonName }}</span>
          </div>
          <div class="wholeLine lineItem">
            <p> <em>颁</em><em>发</em><em>机</em><em>构</em> ：</p>
            <span>山东豸信认证服务有限公司</span>
          </div>
          <div class="lineItem">
            <p> <em>证</em><em>书</em><em>有</em><em>效</em><em>期</em> ：</p>
            <i v-if="Math.floor(certInfo.certDuration / 365) > 0">{{ Math.floor(certInfo.certDuration / 365) }}年</i>
            <i v-if="certInfo.certDuration % 365 > 0">{{ certInfo.certDuration % 365 }}天</i>
          </div>
        </div>
        <div class="certFooter">
          <p>
            项目：<span>{{ certInfo.projectName }}</span>
          </p>
          <p class="com">
            颁发：<span>山东豸信认证服务有限公司</span>
          </p>
          <p class="time"><span>{{ getTime}}</span></p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { IdentType } from '@/utils/typeMatch'
export default {
  data () {
    return {
      showInfo: false,
      isLoad: false,
      certInfo: {},
      page: 1
    }
  },
  components: {},
  filters: {
    IdentType
  },
  computed: {
    getTime () {
      let time = new Date()
      return (
        time.getFullYear() +
        '年 ' +
        (time.getMonth() + 1) +
        '月 ' +
        time.getDate() +
        '日'
      )
    }
  },
  mounted () { },
  methods: {
    getInfo (id) {
      this.getDetails(id)
    },
    handleClose () {
      this.showInfo = false
    },
    getDetails (id) {
      this.isLoad = true
      this.showInfo = true
      this.$api
        .getCertInfo({
          certSn: id
        })
        .then(data => {
          this.isLoad = false
          this.certInfo = data.data
        })
        .catch(() => {
          this.isLoad = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.transactionDetail {
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0px;
  right: 0px;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.66);
  overflow-y: scroll;
  .certDetails {
    margin: 30px auto 22px;
    position: relative;
    width: 570px;
    min-height: 778px;
    background: url('~@/assets/image/personCenter/cert.png') no-repeat center;
    background-size: 570px 100%;
    .certArea {
      position: relative;
      height: 100%;
    }
    .close {
      display: inline-block;
      width: 24px;
      height: 24px;
      font-size: 24px;
      font-weight: 600;
      color: #aeb5be;
      background-size: 24px;
      position: absolute;
      top: 12px;
      right: -40px;
      cursor: pointer;
    }
    .certTop {
      height: 160px;
      width: 100%;
      background: url('~@/assets/image/personCenter/certTop.png') no-repeat
        center bottom;
      background-size: 365px 91px;
    }
    .certContent {
      padding: 60px 60px 0 60px;
      .lineItem {
        color: #303b50;
        line-height: 19px;
        font-size: 14px;
        padding-bottom: 30px;
        display: flex;
        justify-content: flex-start;
        p {
          width: 105px;
          text-align: left;
          margin-right: 10px;
          display: flex;
          justify-content: space-between;
        }
        span {
          text-align: left;
          flex: 1;
        }
      }
    }
    .certFooter {
      padding: 20px 60px 20px;
      p {
        text-align: left;
        padding-bottom: 24px;
        font-weight: bold;
        font-size: 14px;
        color: #303b50;
        line-height: 19px;
        span {
          font-weight: 500;
          padding-left: 10px;
        }
        &.com {
          padding-bottom: 10px;
        }
        &.time {
          width: 424px;
          text-align: right;
          text-decoration: underline;
          padding-bottom: 80px;
          background: url('~@/assets/image/personCenter/certLogo.png') no-repeat
            335px 26px;
          background-size: 89px 40px;
        }
        &.page {
          text-align: center;
        }
      }
    }
  }
  .dialogFooter {
    text-align: center;
    padding-bottom: 30px;
  }
  ::v-deep.el-dialog__body {
    height: 778px;
  }
}
</style>
