<template>
  <div class="commonBox" v-loading="certLoad">
    <sideBar :sideBarList="certTypeList" @changeMenu="changeMenu" />
    <div class="commonMainArea certBox">
      <el-table ref="certTable" :data="tableData" style="width: 100%" class="customizeTable" @filter-change="filterTag">
        <el-table-column prop="signCertSn" label="证书序列号" min-width="220"></el-table-column>
        <el-table-column prop="certType" label="证书类型" min-width="150">
          <template slot-scope="scope">
            <span>{{scope.row.certType|CertType}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="organization" label="所属机构" min-width="220" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.organization">{{scope.row.organization}}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="commonName" label="持有人" min-width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" label="证书状态" :filters="certStatusOption" :column-key="'status'" :filter-multiple='false' min-width="120">
          <template slot-scope="scope">
            <span :class="'statusColor certStatusColor' + scope.row.status">{{scope.row.status|certStatus}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remainDuration" label="剩余证书有效期" min-width="150px">
          <template slot-scope="scope">
            <!-- <span v-show="Math.floor(scope.row.remainDuration/365)>0">{{Math.floor(scope.row.remainDuration/365)}}年</span> -->
            <span v-if="Math.floor(scope.row.remainDuration/365)>0">{{Math.floor(scope.row.remainDuration/365)}}年</span>
            <span v-else-if="Math.ceil(scope.row.remainDuration/365)<0">{{Math.ceil(scope.row.remainDuration/365)}}年</span>
            <span v-else></span>
            <span v-if="Math.abs(scope.row.remainDuration) > 365">{{Math.abs(scope.row.remainDuration%365)}}天</span>
            <span v-else>{{scope.row.remainDuration%365}}天</span>
          </template>
        </el-table-column>
        <el-table-column prop="signCertSn" fixed="right" label="操作" min-width="120">
          <template slot-scope="scope">
            <el-button @click="getInfo(scope.row.signCertSn)" type="text">查看证书</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-list">
        <v-pagination :size="pageSize" :sum="Number(sum)" @page="pageChange" @current="currentChange" :currentPage="pageIndex" />
      </div>
    </div>
    <v-details ref="certDetails" />
  </div>
</template>

<script>
// import Vue from 'vue'
import { certType } from '@/utils/options'
import sideBar from './../components/sideBar.vue'
import { certStatusOption } from '@/utils/options'
import details from '@/components/certDetails.vue'
import pagination from '@/components/pagination.vue'
import { CertType, certStatus } from '@/utils/typeMatch'
export default {
  data() {
    return {
      certLoad: false,
      pageIndex: 1,
      pageSize: 10,
      isLoad: false,
      isShow: false,
      title: '',
      message: '',
      tableData: [],
      orgData: [],
      sum: 0,
      hasAuth: false,
      hasAuthenticate: false,
      authCompanyId: '',
      options: {},
      certType: 2,
      certStatusOption: certStatusOption,
      certTypeList: certType
    }
  },
  components: {
    sideBar,
    'v-pagination': pagination,
    'v-details': details
  },
  filters: {
    CertType,
    certStatus
  },
  mounted() {
    this.getMyCert()
  },
  methods: {
    filterTag(value) {
      for (let key in value) {
        this.options[key] = value[key].join(',') || ''
      }
      this.pageIndex = 1
      this.getMyCert()
    },
    understood() {
      this.isShow = false
    },
    getInfo(id) {
      this.$refs.certDetails.getInfo(id)
    },
    getMyCert() {
      this.certLoad = true
      let data = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        certType: this.certType
      }
      for (let key in this.options) {
        data[key] = this.options[key]
      }
      this.$api.getCertListPageMycert(data).then(data => {
        this.certLoad = false
        if (data.code === '0') {
          this.tableData = data.data.records
          this.sum = Number(data.data.total)
        } else {
          this.tableData = []
          this.sum = 0
        }
      })
    },
    pageChange(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getMyCert()
    },
    currentChange(val) {
      this.pageIndex = val
      this.getMyCert()
    },
    changeMenu(index) {
      this.pageIndex = 1
      this.pageSize = 10
      this.clearFilter()
      if (index === 0) {
        this.certType = 2
      } else {
        this.certType = 3
      }
      this.getMyCert()
    },
    clearFilter() {
      this.$refs.certTable.clearFilter()
    }
  }
}
</script>

<style lang="scss" scoped>
.certBox {
  padding: 41px 32px 72px;
  background: #fff;
  ::v-deep .el-table tr > th {
    background: #f7faff;
    padding: 2px 0;
    color: #6c737b;
    font-weight: normal;
    border-top: 1px solid #dee0e9;
    border-color: #dee0e9;
  }
  ::v-deep .el-table td {
    padding: 8px 0;
    border-color: #dee0e9;
  }
  ::v-deep .el-icon-arrow-down {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background: url('~@/assets/image/personCenter/select.png') no-repeat center;
    background-size: 16px;
    cursor: pointer;
  }
  .statusColor {
    height: 24px;
    line-height: 22px;
    display: inline-block;
    padding: 0 8px;
    color: #e89e1c;
    background: #fef8ec;
    border-radius: 15px;
    border: 1px solid #fbbc4d;
    &.certStatusColor1 {
      color: #52c41a;
      background: #eff9eb;
      border: 1px solid #d4f0c8;
    }
    &.certStatusColor-1,
    &.certStatusColor-4,
    &.certStatusColor-5 {
      color: #e34d59;
      background: #ffeeee;
      border: 1px solid #f7caca;
    }
    &.certStatusColor-2,
    &.certStatusColor-3,
    &.certStatusColor-6,
    &.certStatusColor-7 {
      color: #707276;
      background: #ffffff;
      border: 1px solid #dbdce0;
    }
  }
}
.pager-list {
  padding-top: 12px;
}
</style>